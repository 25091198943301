import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject, Subject } from 'rxjs'
import { PublicService } from './public.service'
import { User } from '@repo/models'

interface AuthData {
    user: User
    token: {
        value: string
        expiredAt: number
    }
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    get user() {
        return this.authSource.value?.user
    }
    get token() {
        return this.authSource.value?.token
    }
    get isLoggedIn() {
        return !!this.user
    }
    authSource = new BehaviorSubject<AuthData | undefined>(undefined)
    private auth = new Subject<AuthData | undefined>()
    private authLocalStorageKey = 'brandhub-auth-2024-01'

    constructor(
        private router: Router,
        private socialAuthService: SocialAuthService,
        private publicService: PublicService,
        private activatedRoute: ActivatedRoute
    ) {
        this.auth.subscribe(this.authSource)

        const localStorageUser = this.userLocalStorageFind()
        this.setAuthData(localStorageUser)
        this.socialAuthWatch()
    }

    socialAuthWatch() {
        this.socialAuthService.authState.subscribe((socialUser) => {
            if (socialUser) this.login(socialUser)
        })
    }

    async login(socialUser: SocialUser) {
        const data = { socialUser }
        this.publicService.login(data).subscribe((res) => {
            this.setAuthData(res.data)
        })
    }

    setAuthData(authData) {
        if (!authData?.token?.value) return
        this.auth.next(authData)
        localStorage.setItem(this.authLocalStorageKey, JSON.stringify(authData))
    }

    userLocalStorageFind() {
        try {
            const dataRaw = localStorage.getItem(this.authLocalStorageKey)
            return JSON.parse(dataRaw)
        } catch (ex) {
            console.log({ ex })
            this.userLocalStorageClear()
        }
    }
    userLocalStorageClear() {
        localStorage.removeItem(this.authLocalStorageKey)
    }

    async userLogout(redirect = this.activatedRoute.snapshot.queryParams.redirect) {
        if (this.user) {
            try {
                this.userLocalStorageClear()
                this.auth.next(undefined)
            } catch (ex) {
                console.error(ex)
            }
        }

        // See if we need to navigate
        const extras = { queryParams: { redirect } }
        this.router.navigate([''], extras)
    }

    tokenGet() {
        return this.token?.value || ''
    }
}
