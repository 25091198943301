import { Component } from '@angular/core'
import { PublicService } from 'app/_services/public.service'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { NgIf, NgFor } from '@angular/common'
import { firstValueFrom } from 'rxjs'
import { Report } from '@repo/models'

@Component({
    selector: 'app-rating-modal',
    templateUrl: './rating-modal.component.html',
    standalone: true,
    imports: [NgIf, NgFor],
})
export class RatingModalComponent {
    title: string
    report: Report
    reportPageIndex: number

    field: string
    simplicateId: string
    preview: boolean

    constructor(
        private bsModalRef: BsModalRef,
        private publicService: PublicService
    ) {}

    async setRating(num) {
        this.report[this.field] = num

        const reportPageIndex = this.reportPageIndex
        const employeeRating = ['customerNPS'].includes(this.field)
        const employeeQuery = this.publicService.setCustomerRating(this.report._id, reportPageIndex, num)

        const customerQuery = this.publicService.setReportRating(this.report._id, this.simplicateId, this.field, num)
        const saveQuery = employeeRating ? employeeQuery : customerQuery
        const saveDo = !this.preview

        if (saveDo) await firstValueFrom(saveQuery)

        this.bsModalRef.hide()
    }
}
