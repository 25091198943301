import { ApplicationConfig, enableProdMode, ErrorHandler, importProvidersFrom, InjectionToken, LOCALE_ID } from '@angular/core'
import { provideRouter } from '@angular/router'
import localeNL from '@angular/common/locales/nl'
import { routes } from './app.routes'
import { environment } from '../environments/environment'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { defineLocale, nlLocale } from 'ngx-bootstrap/chronos'
import { registerLocaleData } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { ModalModule } from 'ngx-bootstrap/modal'
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login'
import { CURRENCY_MASK_CONFIG } from 'ng2-currency-mask'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { HttpConfigInterceptor } from './_services/interceptor.service'
import * as Sentry from '@sentry/angular'

export const BACKEND_URL = new InjectionToken<string>('backendUrl')

registerLocaleData(localeNL, 'nl')
defineLocale('nl', nlLocale)

export function getDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: 'DD/MM/YYYY',
        showWeekNumbers: false,
        locale: 'nl',
        selectFromOtherMonth: true,
    })
}

const host = window.location.host
export const sentryConfig = {
    dsn: environment.sentry.dsn,
    env: environment.production ? 'production' : 'develop',
    location: host.endsWith('localhost:4200') ? 'local' : 'remote',
}
Sentry.init({
    dsn: sentryConfig.dsn,
    environment: sentryConfig.env,
    ignoreErrors: ['Non-Error exception captured'],
})

if (environment.production) {
    enableProdMode()
}

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: BACKEND_URL, useValue: environment.backendUrl },
        provideRouter(routes),
        importProvidersFrom(BrowserModule, ToastrModule.forRoot(), ModalModule.forRoot(), SocialLoginModule, GoogleSigninButtonModule),
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {
                align: 'right',
                allowNegative: true,
                decimal: '.',
                precision: 2,
                prefix: '',
                suffix: '',
                thousands: ' ',
            },
        },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('346758244346-tfsr2rlf7tl4vsbkj6f2n8mukdo2lgnv.apps.googleusercontent.com', {
                            oneTapEnabled: false,
                        }),
                    },
                ],
                onError: (err) => {
                    console.error(err)
                },
            } as SocialAuthServiceConfig,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({}),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ],
}
