import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { CommonModule } from '@angular/common'
import {
    ClassicEditor,
    AccessibilityHelp,
    Autoformat,
    AutoLink,
    BlockQuote,
    Bold,
    Clipboard,
    Code,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListProperties,
    Paragraph,
    SelectAll,
    TextTransformation,
    Undo,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    PictureEditing,
} from 'ckeditor5'

@Component({
    selector: 'form-text-editor',
    template: `
        <div class="mt-2 h-100">
            <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="value" *ngIf="!form" (change)="onChange($event)"></ckeditor>
            <ckeditor [editor]="Editor" [config]="config" [formControl]="form" *ngIf="form"></ckeditor>
        </div>
    `,
    standalone: true,
    imports: [CommonModule, CKEditorModule, ReactiveFormsModule, FormsModule],
    styles: [
        `
            :host {
                display: block;
                height: 100%;
            }
            :host ::ng-deep .ck-editor {
                height: 100%;
            }
            :host ::ng-deep .ck-editor__main {
                height: calc(100% - 40px);
            }
            :host ::ng-deep .ck-editor__editable_inline {
                height: 100%;
            }
        `,
    ],
})
export class FormTextEditorComponent {
    @Output() valueChange = new EventEmitter()
    @Input() form
    @Input() value
    config = {
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'heading',
                '|',
                'bold',
                'italic',
                'code',
                '|',
                'link',
                'blockQuote',
                'uploadImage',
                '|',
                'bulletedList',
                'numberedList',
                'outdent',
                'indent',
            ],
            shouldNotGroupWhenFull: false,
        },
        plugins: [
            AccessibilityHelp,
            Autoformat,
            AutoLink,
            BlockQuote,
            Bold,
            Clipboard,
            Code,
            Essentials,
            GeneralHtmlSupport,
            Heading,
            Indent,
            IndentBlock,
            Italic,
            Link,
            List,
            ListProperties,
            Paragraph,
            SelectAll,
            TextTransformation,
            Undo,
            Image,
            ImageCaption,
            ImageResize,
            ImageStyle,
            ImageToolbar,
            ImageUpload,
            PictureEditing,
        ],
        image: {
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    label: 'Default image width',
                    value: null,
                },
                {
                    name: 'resizeImage:50',
                    label: '50% breedte',
                    value: '50',
                },
                {
                    name: 'resizeImage:75',
                    label: '75% breedte',
                    value: '75',
                },
                {
                    name: 'resizeImage:100',
                    label: '100% breedte',
                    value: '100',
                },
            ],
            toolbar: [
                'imageTextAlternative',
                'toggleImageCaption',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage',
            ],
        },
    }

    Editor = ClassicEditor as any

    onChange = (event) => {
        this.valueChange.emit(event.editor.getData())
    }
}
