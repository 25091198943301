import { Injectable } from '@angular/core'
import { ReqOptions, RequestService } from './request.service'

@Injectable({ providedIn: 'root' })
export class PublicService {
    constructor(private requestService: RequestService) {}

    login(loginData, reqOpts: ReqOptions = null) {
        const url = '/public/auth/login'
        const data = loginData
        return this.requestService.post(url, data, {}, reqOpts)
    }

    getLinkSupplier(linkSupplierId, reqOpts: ReqOptions = null) {
        const url = `/public/link-supplier/${linkSupplierId}`
        return this.requestService.get(url, {}, reqOpts)
    }

    updateLinkSupplierInvoice(linkSupplierId, linkSupplier, reqOpts: ReqOptions = null) {
        const url = `/public/link-supplier/${linkSupplierId}`
        const data = { linkSupplier }
        return this.requestService.post(url, data, {}, reqOpts)
    }

    getReport(reportId, reqOpts: ReqOptions = null) {
        const url = `/public/customer/report/${reportId}`
        return this.requestService.get(url, {}, reqOpts)
    }

    setCustomerRating(reportId, pageIndex, rating, reqOpts: ReqOptions = null) {
        const url = `/public/customer/report/${reportId}/customer-rating/${pageIndex}/${rating}/`
        return this.requestService.get(url, {}, reqOpts)
    }

    setReportRating(reportId, simplicateId, field, rating, reqOpts: ReqOptions = null) {
        const url = `/public/customer/report/${reportId}/${simplicateId}/${field}/${rating}`
        return this.requestService.get(url, {}, reqOpts)
    }

    reportPageCommentSet(reportId, pageIndex, comment, reqOpts: ReqOptions = null) {
        const url = `/public/customer/report/${reportId}/page/${pageIndex}/comment`
        const data = { comment }
        return this.requestService.post(url, data, {}, reqOpts)
    }

    reportOpened(reportId, simplicateId, reqOpts: ReqOptions = null) {
        const url = `/public/customer/report/${reportId}/${simplicateId}/opened`
        return this.requestService.get(url, {}, reqOpts)
    }
}
