import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from './auth.service'

@Injectable({ providedIn: 'root' })
export class UserService {
    get user() {
        return this.authService.user
    }
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {}

    update(user) {
        this.authService.setAuthData({
            ...this.authService.authSource.value,
            user,
        })
    }

    checkUserRole(rolesValid: string[]) {
        if (!this.user) return false
        const roleActive = this.user?.role.id
        const roleValid = rolesValid.includes(roleActive)
        return roleValid
    }

    navigateHome(redirect = this.activatedRoute.snapshot.queryParams['redirect']) {
        if (redirect) return this.router.navigateByUrl(redirect)
        const role = this.user?.role.id
        const roleAdminOrEmployee = ['brandfirm-admin', 'brandfirm-employee'].includes(role)
        const roleSupplier = ['link-supplier'].includes(role)
        if (roleAdminOrEmployee) return this.router.navigate(['main/customer/overview'])
        if (roleSupplier) return this.router.navigate(['supplier/link/overview'])
    }
}
